<template>
  <div>
    <h1>欢迎来到首页！</h1>
    <el-button 
      type="primary" 
      @click="refreshData" 
      :disabled="loading" 
      style="margin-top: 20px;"
    >
      {{ loading ? '加载中...' : '刷新数据' }}
    </el-button>
    <!-- 前三页：QLI、APool、Solutions 计算 -->
    <el-tabs v-model="activeTab">
      <el-tab-pane label="QLI 计算" name="qlitab">
        <el-input 
          v-model="qliSolInput" 
          placeholder="请输入QLI的sol数量" 
          style="margin-bottom: 10px; width: 200px;" 
        />
        <p >share/sol 不固定,通常95-100share/Solutions </p>
        <p>估算 QLI的FIXED 85池Sol Qubic: {{ qliSolResult }}</p>
        <p>bonus池(1/2)QLI数量: {{ qliAdditionalResult }}</p>
      </el-tab-pane>

      <el-tab-pane label="APool 计算" name="apooltab">
        <el-input 
          v-model="apoolShareInput" 
          placeholder="请输入APool share" 
          style="margin-bottom: 10px; width: 200px;" 
        />
        <p v-if="data">APool: {{ data.apoolShare }} shares/sol</p>
        <p>等效APool的sol: {{ apoolSolResult }}</p>
        <p>预估APool的收益: {{ apoolAdditionalResult }}</p>
      </el-tab-pane>

      <el-tab-pane label="Solutions 计算" name="solutionstab">
        <el-input 
          v-model="solutionsShareInput" 
          placeholder="请输入Solutions的share" 
          style="margin-bottom: 10px; width: 200px;" 
        />
        <p v-if="data">Solutions: {{ data.solutionsShare }} shares/sol</p>
        <p>等效Solutions的sol: {{ solutionsSolResult }}</p>
        <p>(基于85池仅参考,具体以实际计算)</p>
        <p>预估Solutions的收益: {{ solutionsAdditionalResult }}</p>
      </el-tab-pane>
    </el-tabs>

    <!-- 第四页：详细数据 -->
    <div style="margin-top: 20px;">
      <h2>详细数据</h2>
      <el-card v-if="data" class="box-card">
        <p>估算时候的回合ID: {{ data.roundId }}</p>
        <p>估算FIXED85池SolQubic: {{ data.estimateSolQubic }}</p>
        <p>总sol: {{ data.totalNetsol }}</p>
        <p>估算总sol: {{ data.estimateTotalNetsol }}</p>
        <p>剩余回合: {{ data.remainRound }}</p>
        <p>(基于前4回合)每回合全网出sol平均值: {{ data.avgRoundNetsol }}</p>
        <p>(基于前4回合)算力平均值: {{ data.avgRoundHash }}</p>
        <p>估算平均分数: {{ data.estimateAvgScore }}</p>
        <p>估算最终平均分数(+6%): {{ data.estimateAvgScoreFinal }}</p>
        <p>APool: {{ data.apoolShare }} shares/sol</p>
        <p>QLI份额: {{ data.qliShare || '无' }}</p>
        <p>Solutions: {{ data.solutionsShare }} shares/sol</p>
        <p>估算APOOL每Sol/day的its: {{ data.estimateItsPerSolDay }}</p>
        <p>更新时间: {{ data.updateTime }}</p>
      </el-card>
      <div v-else>
        <p>数据加载中...</p>
      </div>
    </div>


  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import axios from 'axios';

export default {
  name: 'HomePage',
  setup() {
    const data = ref(null); // 响应式数据
    const loading = ref(true); // 加载状态
    const activeTab = ref('qlitab'); // 当前激活的标签页

    // 输入数据
    const qliSolInput = ref('');
    const apoolShareInput = ref('');
    const solutionsShareInput = ref('');

    // 计算结果
    const qliSolResult = computed(() => {
      return data.value 
        ? (Number(qliSolInput.value) * data.value.estimateSolQubic).toFixed(2) 
        : '无';
    });
    const apoolSolResult = computed(() => {
      return data.value && data.value.apoolShare
        ? (Number(apoolShareInput.value) / data.value.apoolShare).toFixed(2)
        : '无';
    });
    const solutionsSolResult = computed(() => {
      return data.value && data.value.solutionsShare
        ? (Number(solutionsShareInput.value) / data.value.solutionsShare).toFixed(2)
        : '无';
    });

    // 计算752365384 / 估算最终平均分数再乘以QLI数量
    const qliAdditionalResult = computed(() => {
      return data.value && data.value.estimateAvgScoreFinal 
        ? (752365384 / data.value.estimateAvgScoreFinal * Number(qliSolInput.value)).toFixed(2)
        : '无';
    });

    // 计算967,326,922 / 估算最终平均分数再乘以APool Sol数量
    const apoolAdditionalResult = computed(() => {
      return data.value && data.value.estimateAvgScoreFinal && apoolSolResult.value 
        ? (967326922 / data.value.estimateAvgScoreFinal * Number(apoolSolResult.value)).toFixed(2)
        : '无';
    });

    // 计算data.estimateSolQubic 乘以Solutions Sol数量
    const solutionsAdditionalResult = computed(() => {
      return data.value && solutionsSolResult.value 
        ? (data.value.estimateSolQubic * Number(solutionsSolResult.value)).toFixed(2)
        : '无';
    });

    // 获取数据的方法
    const fetchData = async () => {
      try {
        loading.value = true;
        const apiBaseUrl = process.env.VUE_APP_BASE_URL_API;
        const response = await axios.get(`${apiBaseUrl}/api/qubic/calculator`);
        sessionStorage.setItem('homePageData', JSON.stringify(response.data.data));
        data.value = response.data.data;
      } catch (error) {
        console.error('获取计算器数据失败:', error);
      } finally {
        loading.value = false;
      }
    };

    // 尝试从 sessionStorage 获取缓存数据
    const loadCachedData = () => {
      const cachedData = sessionStorage.getItem('homePageData');
      if (cachedData) {
        data.value = JSON.parse(cachedData);
        loading.value = false;
      } else {
        fetchData();
      }
    };

    // 刷新数据
    const refreshData = () => {
      fetchData();
    };

    // 在组件挂载时调用
    onMounted(loadCachedData);

    return {
      data,
      loading,
      activeTab,
      qliSolInput,
      apoolShareInput,
      solutionsShareInput,
      qliSolResult,
      apoolSolResult,
      solutionsSolResult,
      qliAdditionalResult,
      apoolAdditionalResult,
      solutionsAdditionalResult,
      refreshData,
    };
  },
};
</script>

<style>
/* 可根据需求添加样式 */
</style>
