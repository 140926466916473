<template>
  <el-container style="height: 100vh;">
    <!-- 左侧导航 -->
    <el-aside width="200px" style="background-color: #f5f5f5;">
      <el-menu :default-active="activeMenu" @select="handleMenuSelect">
        <el-menu-item index="/">首页</el-menu-item>
        <el-menu-item index="/qubicPools">矿池数据</el-menu-item>
        <el-menu-item index="/qubicRounds">回合时间数据</el-menu-item>
      </el-menu>
    </el-aside>

    <!-- 右侧内容 -->
    <el-container>
      <el-header style="background-color: #b3c0d1; text-align: center;">
  <div style="float: right; text-align: left; margin-right: 20px;">
    <div style="margin-bottom: 5px;">
      <a href="https://minership.xyz/" target="_blank" style="color: #1E90FF; text-decoration: none; font-weight: bold; font-size: 14px;">
        gamergamer的计算器
      </a>
    </div>
    <div>
      <a href="https://tool.qubic.site/" target="_blank" style="color: #1E90FF; text-decoration: none; font-weight: bold; font-size: 14px;">
        XARKUR的计算器
      </a>
    </div>
  </div>
  <div style="float: right; margin-right: 40px;">
    <strong>友情链接:</strong>
  </div>
</el-header>


      <el-main>
        <router-view />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import { ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export default {
  setup() {
    const activeMenu = ref('/');
    const route = useRoute();
    const router = useRouter();

    // 路由变化时更新选中的菜单项
    watch(
      () => route.path,
      (newPath) => {
        activeMenu.value = newPath;
      },
      { immediate: true }
    );

    const handleMenuSelect = (index) => {
      router.push(index); // 使用 vue-router 导航
    };

    return {
      activeMenu,
      handleMenuSelect,
    };
  },
};
</script>
