<template>
    <div>
      <el-table :data="qubicPools" style="width: 100%" border>
        <el-table-column prop="id" label="回合ID" width="50"></el-table-column>
        <el-table-column prop="updateTime" label="UTC+8" width="200"></el-table-column>
        <el-table-column prop="netHash" label="全网算力(基于A)" width="120" :formatter="formatHashNumber"></el-table-column>
        <el-table-column prop="netSol" label="全网回合出块" width="100"></el-table-column>
        <el-table-column prop="apoolHash" label="A池算力" width="120" :formatter="formatHashNumber"></el-table-column>
        <el-table-column prop="apoolSol" label="A池回合出块" width="100"></el-table-column>
        <el-table-column prop="minerlabHash" label="M池算力(solo+pplns)" width="120" :formatter="formatHashNumber"></el-table-column>
        <el-table-column prop="minerlabSol" label="M池回合出块(solo)" width="100"></el-table-column>
        <el-table-column prop="solutionsHash" label="R池算力" width="120" :formatter="formatHashNumber"></el-table-column>
        <el-table-column prop="solutionsSol" label="R池回合出块" width="100"></el-table-column>
      </el-table>
    </div>
  </template>
  
  <script>
  export default {
    name: 'OriginalPage',
    props: {
      qubicPools: {
        type: Array,
        required: true,
      },
    },
    methods: {
      formatHashNumber(row, column, cellValue) {
        if (typeof cellValue === 'number') {
          return cellValue.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
        return cellValue;
      },
    },
  };
  </script>
  