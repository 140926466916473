<template>
  <div>
    <h2>回合列表UTC+8</h2>

    <!-- 筛选条件 -->
    <div style="margin-bottom: 20px;">
      <el-row>
        <!-- 开始时间 -->
        <el-col :span="6">
          <el-date-picker
            v-model="filters.startTime"
            type="datetime"
            placeholder="开始时间"
            style="width: 100%;"
          />
        </el-col>

        <!-- 结束时间 -->
        <el-col :span="6">
          <el-date-picker
            v-model="filters.endTime"
            type="datetime"
            placeholder="结束时间"
            style="width: 100%;"
          />
        </el-col>

        <!-- ID范围 -->
        <el-col :span="6">
          <el-input-number
            v-model="filters.startId"
            placeholder="开始ID"
            style="width: 100%;"
            :min="0"
          />
        </el-col>

        <el-col :span="6">
          <el-input-number
            v-model="filters.endId"
            placeholder="结束ID"
            style="width: 100%;"
            :min="0"
          />
        </el-col>
      </el-row>

      <!-- 搜索按钮 -->
      <el-button
        type="primary"
        @click="applyFilters"
        :disabled="loading"
        style="margin-top: 10px;"
      >
        {{ loading ? '加载中...' : '搜索' }}
      </el-button>
    </div>

    <!-- 数据加载中 -->
    <el-spin v-if="loading" size="large" tip="加载中..."></el-spin>

    <!-- 数据加载完成 -->
    <el-table v-else :data="qubicRounds" style="width: 100%" border>
      <el-table-column prop="id" label="回合ID" width="100"></el-table-column>
      <el-table-column prop="startTime" label="挖矿开始时间" width="200"></el-table-column>
      <el-table-column prop="changeTime" label="挖矿转闲置" width="200"></el-table-column>
      <el-table-column prop="endTime" label="闲置结束" width="200"></el-table-column>
      <el-table-column label="挖矿开始到转闲置（分钟）" width="180">
        <template #default="{ row }">
          {{ calculateTimeDiff(row.startTime, row.changeTime) }}
        </template>
      </el-table-column>
      <el-table-column label="闲置到结束（分钟）" width="180">
        <template #default="{ row }">
          {{ calculateTimeDiff(row.changeTime, row.endTime) }}
        </template>
      </el-table-column>
    </el-table>

    <!-- 显示统计数据 -->
    <div v-if="qubicRounds.length > 0" style="margin-top: 20px; font-weight: bold;">
      <div>挖矿开始到转闲置总和: {{ pageTotals.miningToIdleTotal || 0 }} 分钟</div>
      <div>闲置到结束总和: {{ pageTotals.idleToEndTotal || 0 }} 分钟</div>
      <div>挖矿开始到转闲置占比: {{ (pageTotals.miningToIdlePercentage || 0).toFixed(2) }}%</div>
      <div>闲置到结束占比: {{ (pageTotals.idleToEndPercentage || 0).toFixed(2) }}%</div>
    </div>

    <!-- 分页组件 -->
    <el-pagination
      v-if="qubicRounds.length > 0"
      :current-page="page"
      :page-size="pageSize"
      :total="totalItems"
      @current-change="handlePageChange"
      @size-change="handlePageSizeChange"
      style="margin-top: 20px;"
      :page-sizes="[5, 10, 20, 50]"
      layout="sizes, prev, pager, next, jumper"
      background
    ></el-pagination>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import dayjs from 'dayjs';

export default {
  name: 'QubicRounds',
  setup() {
    const qubicRounds = ref([]); // 数据
    const loading = ref(false); // 加载状态
    const filters = ref({
      startTime: null, // 开始时间
      endTime: null, // 结束时间
      startId: null, // 起始ID
      endId: null, // 结束ID
    }); // 筛选条件
    const page = ref(1); // 当前页
    const pageSize = ref(10); // 每页大小
    const totalItems = ref(0); // 总条数

    // 统计数据
    const pageTotals = ref({
      miningToIdleTotal: 0,
      idleToEndTotal: 0,
      miningToIdlePercentage: 0,
      idleToEndPercentage: 0,
    });

    // 计算时间差函数
    const calculateTimeDiff = (startTime, endTime) => {
      if (!startTime || !endTime) return 'N/A';
      const start = new Date(startTime);
      const end = new Date(endTime);
      const diffInMs = end - start;
      if (diffInMs < 0) return 'N/A';
      return (diffInMs / 1000 / 60).toFixed(0);
    };

    // 计算当前页的总和和占比
    const calculatePageTotals = () => {
      let miningToIdleTotal = 0;
      let idleToEndTotal = 0;

      qubicRounds.value.forEach((row) => {
        const miningToIdle = calculateTimeDiff(row.startTime, row.changeTime);
        const idleToEnd = calculateTimeDiff(row.changeTime, row.endTime);

        if (miningToIdle !== 'N/A') miningToIdleTotal += parseFloat(miningToIdle);
        if (idleToEnd !== 'N/A') idleToEndTotal += parseFloat(idleToEnd);
      });

      const total = miningToIdleTotal + idleToEndTotal;
      pageTotals.value = {
        miningToIdleTotal,
        idleToEndTotal,
        miningToIdlePercentage: total ? (miningToIdleTotal / total) * 100 : 0,
        idleToEndPercentage: total ? (idleToEndTotal / total) * 100 : 0,
      };
    };

    // 获取数据
    const fetchUsers = async () => {
      try {
        loading.value = true; // 开始加载
        const apiBaseUrl = process.env.VUE_APP_BASE_URL_API;

        const params = new URLSearchParams({
          page: page.value,
          pageSize: pageSize.value,
          ...(filters.value.startTime
            ? { startTime: dayjs(filters.value.startTime).format('YYYY-MM-DD HH:mm:ss') }
            : {}),
          ...(filters.value.endTime
            ? { endTime: dayjs(filters.value.endTime).format('YYYY-MM-DD HH:mm:ss') }
            : {}),
          ...(filters.value.startId !== null
            ? { startId: filters.value.startId }
            : {}),
          ...(filters.value.endId !== null
            ? { endId: filters.value.endId }
            : {}),
        });

        const response = await axios.get(`${apiBaseUrl}/api/qubic/rounds?${params.toString()}`);
        const fetchedData = response.data.data || {};
        sessionStorage.setItem(
          'qubicData',
          JSON.stringify({
            rounds: fetchedData.rows || [],
            total: fetchedData.total || 0,
            page: page.value,
            pageSize: pageSize.value,
          })
        );

        qubicRounds.value = fetchedData.rows || [];
        totalItems.value = fetchedData.total || 0;

        calculatePageTotals();
      } catch (error) {
        console.error('获取回合数据失败:', error);
      } finally {
        loading.value = false; // 停止加载
      }
    };

    // 初始化页面数据
    const initPage = () => {
      const cachedData = JSON.parse(sessionStorage.getItem('qubicData'));
      if (cachedData) {
        qubicRounds.value = cachedData.rounds;
        totalItems.value = cachedData.total;
        page.value = cachedData.page;
        pageSize.value = cachedData.pageSize;
        calculatePageTotals();
      } else {
        fetchUsers();
      }
    };

    // 处理分页切换
    const handlePageChange = (newPage) => {
      page.value = newPage;
      fetchUsers();
    };

    // 处理页面大小切换
    const handlePageSizeChange = (newPageSize) => {
      pageSize.value = newPageSize;
      page.value = 1;
      fetchUsers();
    };

    // 应用筛选条件
    const applyFilters = () => {
      sessionStorage.removeItem('qubicData'); // 清除缓存
      page.value = 1; // 筛选时返回第一页
      fetchUsers(); // 重新获取数据
    };

    // 页面加载时初始化
    onMounted(initPage);

    return {
      qubicRounds,
      loading,
      calculateTimeDiff,
      filters,
      page,
      pageSize,
      totalItems,
      pageTotals,
      applyFilters,
      handlePageChange,
      handlePageSizeChange,
    };
  },
};
</script>
