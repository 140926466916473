<template>
  <div>
    <p style="margin-bottom: 10px; color: #666;">
  由于m池接口无法获取pplns的sol数量，目前只能统计solo的出块，还好m池pplns的人不多 约10%，可以忽略。顺便修复了r池的统计不稳的问题。
</p>
    <el-button type="primary" @click="refreshData" :disabled="loading" style="margin-bottom: 10px;">
      {{ loading ? '加载中...' : '刷新数据' }}
    </el-button>

    <el-tabs v-model="activeTab">
      <!-- 原始版本 -->
      <el-tab-pane label="原始版本" name="original">
        <OriginalPage :qubicPools="qubicPools" />
      </el-tab-pane>
      <!-- 修改后的版本 -->
      <el-tab-pane label="详情" name="updated">
        <UpdatedPage :qubicPools="qubicPools" />
      </el-tab-pane>
    </el-tabs>

    <!-- 加载动画 -->
    <el-spin v-if="loading" size="large" tip="加载中..."></el-spin>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import OriginalPage from './OriginalPage.vue';
import UpdatedPage from './UpdatedPage.vue';

export default {
  name: 'QubicPoolsComparison',
  components: { OriginalPage, UpdatedPage },
  setup() {
    const activeTab = ref('original'); // 默认显示原始版本
    const qubicPools = ref([]); // 矿池数据
    const loading = ref(true); // 加载状态

    // 从 sessionStorage 获取数据
    const loadFromSession = () => {
      const storedData = sessionStorage.getItem('qubicPools');
      if (storedData) {
        qubicPools.value = JSON.parse(storedData);
        loading.value = false;
      } else {
        fetchData(); // 如果没有存储数据，则调用请求
      }
    };

    // 数据请求函数
    const fetchData = async () => {
      try {
        loading.value = true;
        const apiBaseUrl = process.env.VUE_APP_BASE_URL_API;
        const response = await axios.post(`${apiBaseUrl}/api/qubic/pools`);
        qubicPools.value = response.data.data.rows;
        // 将数据存入 sessionStorage
        sessionStorage.setItem('qubicPools', JSON.stringify(qubicPools.value));
      } catch (error) {
        console.error('加载数据失败:', error);
      } finally {
        loading.value = false;
      }
    };

    // 刷新数据
    const refreshData = () => {
      fetchData(); // 手动刷新时重新请求并覆盖存储
    };

    // 初始化加载
    onMounted(loadFromSession);

    return { activeTab, qubicPools, loading, refreshData };
  },
};
</script>

<style>
h1 {
  text-align: center;
  margin-bottom: 20px;
}
</style>
