import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../views/Home.vue';
import Settings from '@/views/Settings.vue';
import QubicPools from '@/views/QubicPools.vue';
import QubicRounds from '@/views/QubicRounds.vue';
const routes = [
    { path: '/', name: 'HomePage', component: HomePage },
    { path: '/settings', name: 'SettingsPage', component: Settings },
    { path: '/qubicPools', name: 'QubicPools', component: QubicPools },
    { path: '/qubicrounds', name: 'QubicRound', component: QubicRounds }
  ];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
