<template>
    <div>
      <el-table :data="qubicPools" style="width: 100%" border>
        <el-table-column prop="id" label="回合ID" width="50"></el-table-column>
        <el-table-column prop="updateTime" label="UTC+8" width="200"></el-table-column>
        <el-table-column prop="apoolHash" label="A池算力" width="120" :formatter="formatHashNumber"></el-table-column>
        <el-table-column label="A池回合出块 / 效率" width="180">
          <template #default="scope">
            <div>
              {{ scope.row.apoolSol }}
              <span v-if="scope.row.apoolHash && scope.row.apoolSol">
                ({{ (scope.row.apoolHash / scope.row.apoolSol).toFixed(2) }})
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="minerlabHash" label="M池算力" width="120" :formatter="formatHashNumber"></el-table-column>
        <el-table-column label="M池回合出块 / 效率" width="180">
          <template #default="scope">
            <div>
              {{ scope.row.minerlabSol }}
              <span v-if="scope.row.minerlabHash && scope.row.minerlabSol">
                ({{ (scope.row.minerlabHash / scope.row.minerlabSol).toFixed(2) }})
              </span>
              <div v-if="scope.row.apoolHash && scope.row.apoolSol && scope.row.minerlabHash && scope.row.minerlabSol">
                折扣: 
                {{ ((scope.row.apoolHash / scope.row.apoolSol) / (scope.row.minerlabHash / scope.row.minerlabSol)).toFixed(2) }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="solutionsHash" label="R池算力" width="120" :formatter="formatHashNumber"></el-table-column>
        <el-table-column label="R池回合出块 / 效率" width="180">
          <template #default="scope">
            <div>
              {{ scope.row.solutionsSol }}
              <span v-if="scope.row.solutionsHash && scope.row.solutionsSol">
                ({{ (scope.row.solutionsHash / scope.row.solutionsSol).toFixed(2) }})
              </span>
              <div v-if="scope.row.apoolHash && scope.row.apoolSol && scope.row.solutionsHash && scope.row.solutionsSol">
                折扣: 
                {{ ((scope.row.apoolHash / scope.row.apoolSol) / (scope.row.solutionsHash / scope.row.solutionsSol)).toFixed(2) }}
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </template>
  
  <script>
  export default {
    name: 'UpdatedPage',
    props: {
      qubicPools: {
        type: Array,
        required: true,
      },
    },
    methods: {
      formatHashNumber(row, column, cellValue) {
        if (typeof cellValue === 'number') {
          return cellValue.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
        return cellValue;
      },
    },
  };
  </script>
  